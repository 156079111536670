/* @flow */
import './TagList.scss';

import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CancelToken } from 'axios';
import cx from 'classnames';
import pluralize from 'pluralize';
import type { Element } from 'react';
import React, { type Node, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
// $FlowFixMe
import Creatable from 'react-select/creatable';
import useEffectAPI from 'utils/APIFetch/useEffectAPI';

import {
  type EditableTypes,
  DELETE_ONLY_PERMISSION,
  NOT_EDITABLE_PERMISSION,
} from '../../permissionUtils';
import TagItem from './TagItem';

const UserOptRender = ({
  className,
  value,
}: {
  className?: string,
  value: {
    title: string,
    subtitle: string,
  },
}) => (
  <div className={cx('ProviderOptRender', className)}>
    {value.title}
    {value.subtitle && (
      <div className="text-smaller text-secondary">{value.subtitle}</div>
    )}
  </div>
);
UserOptRender.defaultProps = {
  className: '',
};

export const UserValueOption = (props: {
  data: {
    title: string,
    subtitle: string,
  },
}): Node => (
  <components.SingleValue className="w-100" {...props}>
    <UserOptRender className="py-2" value={props.data} />
  </components.SingleValue>
);

export const UserOption = (props: {
  innerRef: Node,
  innerProps: Object,
  data: {
    value: string,
    data: {
      title: string,
      subtitle: string,
    },
  },
}): Node => (
  <components.Option {...props}>
    <UserOptRender value={props.data.data} />
  </components.Option>
);

export type TagT<T> = {|
  id: string,
  label: string,
  subtitle?: ?string,
  opt?: ?{ isDefault: boolean },
  value: T,
|};

type Props<T, U: TagT<T> | $Diff<TagT<T>, { value: any }>> = {|
  tags: Array<U>,
  onFetchAllTags: (CancelToken) => Promise<?Array<U>>,
  headerContent?: ?({
    refetch: () => void,
    tagContent: U,
  }) => Node,
  tagClassName?: string,
  className?: string,
  onSelect?: ?(U) => Promise<void> | void,
  onDeleteTag: ({ id: string, label: string }) => Promise<void>,
  onAddTag: ({ id: string, label: string }) => Promise<void>,
  editablePermission: EditableTypes,
  itemType: 'Group' | 'Tag',
  itemLabel: string,

  // passed if component should allow creating new tags
  onCreate?: ?({ label: string }) => Promise<?string>,
|};

const TagList = <T, U: TagT<T> | $Diff<TagT<T>, { value: any }>>({
  tags,
  onFetchAllTags,
  onDeleteTag,
  onAddTag,
  itemType,
  onSelect,
  headerContent,
  className,
  onCreate,
  editablePermission,
  itemLabel,
  tagClassName,
}: Props<T, U>): Element<'div'> => {
  const [allTags, setAllTags] = useState(null);
  const [refetchToken, setRefetchToken] = useState(0);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const currentTagIds = new Set(tags.map(({ id }) => id));
  useEffect(
    useEffectAPI(async (cancelToken) => {
      if (isLoadingTags) {
        const resp = await onFetchAllTags(cancelToken);
        setAllTags(resp);
      }
    }),
    [isLoadingTags, refetchToken]
  );

  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const SelectComponent = onCreate ? Creatable : Select;
  const hasAddGroupPermission =
    editablePermission !== NOT_EDITABLE_PERMISSION &&
    editablePermission !== DELETE_ONLY_PERMISSION;
  return (
    <div className={cx('TagList d-flex flex-wrap', className)}>
      {tags.map((tag) => {
        const tagComponent = (
          <TagItem
            tag={{
              id: tag.id,
              label: tag.label,
              subtitle: tag.subtitle,
            }}
            key={tag.id}
            className={tagClassName}
            isDefault={tag.opt ? tag.opt.isDefault : false}
            itemType={itemType}
            onSelect={
              onSelect
                ? async () => {
                    onSelect(tag);
                  }
                : null
            }
            onDelete={async () => {
              const { id, label } = tag;
              await onDeleteTag({ id, label });
            }}
            editablePermission={editablePermission}
          />
        );
        return headerContent ? (
          <div className="d-flex w-100" key={tag.id}>
            {tagComponent}
            {headerContent({
              refetch: () => {
                setRefetchToken(new Date().getTime());
              },
              tagContent: tag,
            })}
          </div>
        ) : (
          tagComponent
        );
      })}
      {tags.length === 0 && <span className="text-secondary">-</span>}
      <div
        className={cx('d-inline-flex align-items-center', {
          'border mt-2 pr-3 bg-white': isAddingGroup,
        })}
      >
        {isAddingGroup && (
          <SelectComponent
            className="text-small"
            classNamePrefix="TagList"
            components={{
              Option: UserOption,
              SingleValue: UserValueOption,
            }}
            placeholder={`Search for ${pluralize(itemLabel)}`}
            options={(allTags || [])
              .filter(({ id }) => !currentTagIds.has(id))
              .map(({ id, label, subtitle }) => ({
                value: id,
                data: {
                  title: label,
                  subtitle,
                },
                label: `${label} ${subtitle ? `(${subtitle})` : ''}`,
              }))}
            isLoading={allTags == null}
            onChange={async (data: {
              label: string,
              __isNew__: boolean,
              value: string,
            }) => {
              // label contains item name (currently only used
              // in ExerciseCreationPane --> checks if item with same label
              // exists, and if it does not, then marked as a new item that
              // needs to be created
              if (onCreate && data.__isNew__) {
                const createdTagId = await onCreate({ label: data.value });
                if (createdTagId != null) {
                  setRefetchToken(new Date().getTime());
                  await onAddTag({ label: data.value, id: createdTagId });
                  setIsAddingGroup(false);
                }
              } else {
                await onAddTag({ label: data.label, id: data.value });
                setIsAddingGroup(false);
              }
            }}
          />
        )}
        {hasAddGroupPermission && (
          <Button
            variant="link"
            className="text-smaller p-0 pl-1"
            onClick={() => {
              setIsAddingGroup((curAddValue) => !curAddValue);
              setIsLoadingTags(true);
            }}
          >
            <FontAwesomeIcon icon={isAddingGroup ? faTimes : faPlus} />
          </Button>
        )}
      </div>
    </div>
  );
};

TagList.defaultProps = {
  className: '',
  tagClassName: '',
  onCreate: null,
  headerContent: null,
  onSelect: null,
};

export default TagList;
