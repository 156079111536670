/* @flow */
import moment from 'moment-timezone';
import type { DateInMillis } from 'symptoTypes/provider';
import type {
  SpecialVariableValueT,
  SuperscoreVariableTypesT,
} from 'symptoTypes/sympto-provider-creation-types';

import { INVALID_TAG } from './patientSurveyTagOptions';

// eslint-disable-next-line import/prefer-default-export
export const calculateSpecialVariable = ({
  variableValue,
  instrumentMetadata,
  patientTimeZone,
  patientSurveyTags,
  patientTvId,
  patientSurveyId,
  reminderTimestampsInMS,
  variableName,
  patientCampaign,
  triggeredStatuses,
}: {
  variableValue: SpecialVariableValueT,
  instrumentMetadata: {
    responseCompletion: 'Empty' | 'Partial' | 'Full',
    responseUpdatedAt: Date,
    createdAt: Date,
    isAutocompletedByInstrumentSuperscore: boolean,
    hasResponseUpdatedByDiffView: boolean,
  },
  patientCampaign: ?{
    campaignId: string,
    campaignName: string,
    passedVariableValue: ?{
      expression: string,
      value: SuperscoreVariableTypesT,
    },
  },
  triggeredStatuses: {
    [ClinicStatusId: string]: Array<{
      deprecated: boolean,
      hidden: boolean,
      deprecatedAt: ?Date,
      deprecatedByNurseId: ?string,
      createdAt: Date,
    }>,
  },
  patientTimeZone: string,
  reminderTimestampsInMS: Array<DateInMillis>,
  variableName: string,
  patientTvId: string,
  patientSurveyId: string,
  patientSurveyTags: ?Array<string>,
}): SuperscoreVariableTypesT => {
  if (variableValue.value === 'instrument-start-time') {
    return instrumentMetadata.createdAt.getTime();
  }
  if (variableValue.value === 'instrument-start-time-mm-dd-yyyy') {
    return moment(instrumentMetadata.createdAt, patientTimeZone).format(
      'MM/DD/YYYY'
    );
  }
  if (variableValue.value === 'instrument-is-completed') {
    return instrumentMetadata.responseCompletion === 'Full';
  }
  if (variableValue.value === 'patient-tvid') {
    return patientTvId;
  }
  if (variableValue.value === 'current-date') {
    return moment.tz(patientTimeZone).format('MM/DD/YYYY');
  }
  if (variableValue.value === 'timezone-offset') {
    return moment.tz(patientTimeZone).format('Z');
  }
  if (variableValue.value === 'empty-array') {
    return ([]: Array<null | string | number>);
  }
  if (variableValue.value === 'null-value') {
    return null;
  }
  if (variableValue.value === 'current-date-in-millis') {
    return moment().valueOf();
  }
  if (variableValue.value === 'patient-survey-id') {
    return patientSurveyId;
  }
  if (variableValue.value === 'last-response-update') {
    return instrumentMetadata.responseUpdatedAt.getTime();
  }
  if (variableValue.value === 'response-completion-status') {
    return instrumentMetadata.responseCompletion;
  }
  if (variableValue.value === 'has-invalid-survey-tag') {
    return (patientSurveyTags || []).includes(INVALID_TAG);
  }
  if (variableValue.value === 'did-instrument-auto-complete') {
    return instrumentMetadata.isAutocompletedByInstrumentSuperscore;
  }
  if (variableValue.value === 'has-response-updated-by-diff-view') {
    return instrumentMetadata.hasResponseUpdatedByDiffView;
  }
  if (variableValue.value === 'campaign-id') {
    return patientCampaign?.campaignId ?? null;
  }
  if (variableValue.value === 'campaign-name') {
    return patientCampaign?.campaignName ?? null;
  }
  if (variableValue.value === 'triggered-campaign-passed-variable-value') {
    return patientCampaign?.passedVariableValue?.value ?? null;
  }
  if (variableValue.value === 'triggered-statuses') {
    return triggeredStatuses ?? {};
  }
  if (variableValue.value === 'is-triggered-by-campaign') {
    // if patientCampaign is null, then it is not triggered by a campaign
    return patientCampaign != null;
  }
  if (variableValue.value === 'last-reminder-timestamp-in-ms') {
    return reminderTimestampsInMS.length > 0
      ? Math.max(...reminderTimestampsInMS)
      : null;
  }
  if (variableValue.value === 'reminder-timestamps-in-ms') {
    return reminderTimestampsInMS.map(
      (timestamp): string | number | null => timestamp
    );
  }
  // eslint-disable-next-line no-console
  console.log((variableValue.value: empty));
  throw new Error(
    `Variable ${variableName}: Maps to an invalid special variable`
  );
};
