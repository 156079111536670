/* @flow */
import { Parser } from 'expr-eval';
import _ from 'lodash';
import moment from 'moment-timezone';
import type { SuperscoreVariableTypesT } from 'symptoTypes/sympto-provider-creation-types';

const PARSER_SETTINGS = {
  operators: {
    add: true,
    concatenate: true,
    conditional: true,
    divide: true,
    factorial: true,
    multiply: true,
    power: true,
    remainder: true,
    subtract: true,

    // and, or, not, <, ==, !=, etc.
    logical: true,
    comparison: true,

    in: true,
    assignment: true,
  },
};

const EXPRESSION_PARSER_SETTINGS = {
  operators: {
    // and, or, not, <, ==, !=, etc.
    logical: true,
  },
};

const formatVariableContentStr = (content: string) =>
  content.replace(/[{}]/g, '');

function evaluateExpression<T>({
  expression,
  variableValues,
  simplifiedExpression,
}: {
  expression: string,
  variableValues: { [variableName: string]: SuperscoreVariableTypesT },
  simplifiedExpression?: boolean,
}): T {
  const parserSettings = simplifiedExpression
    ? EXPRESSION_PARSER_SETTINGS
    : PARSER_SETTINGS;
  const parser = new Parser(parserSettings);
  parser.consts = { true: true, false: false };
  parser.functions.isNull = (arg1) => arg1 == null;
  parser.functions.isNullOrEmptyStr = (arg1) => arg1 == null || arg1 === '';
  parser.functions.isValidDate = (arg1, dateFormat) => {
    const formattedDate = moment(arg1, dateFormat, true);
    return formattedDate.isValid();
  };
  parser.functions.convertToTimezone = (arg1, timezone, format) =>
    moment(arg1).tz(timezone).format(format);
  parser.functions.formatDate = (arg1, arg2, arg3) => {
    if (arg3 === undefined) {
      // if arg3 udnefined, arg2 is what the final format is
      return moment(arg1).format(arg2);
    }
    // if arg3 is defined, arg2 is current format, arg3 is the format to convert to
    return moment(arg1, arg2).format(arg3);
  };
  parser.functions.subtractDate = (arg1, arg2) => {
    const allowedDateFormats = [
      'YYYY-MM-DD',
      'MM/DD/YYYY',
      'M/D/YYYY',
      'MM.DD.YYYY',
      'M.D.YYYY',
      'MM. DD. YYYY',
      'M. D. YYYY',
    ];

    const multiDateValidator = (value) =>
      _.head(
        _.compact(
          allowedDateFormats.map((dateFormat) => {
            const formattedDate = moment(value, dateFormat, true);
            return formattedDate.isValid() ? formattedDate : null;
          })
        )
      );
    const arg1Date = multiDateValidator(arg1);
    const arg2Date = multiDateValidator(arg2);
    if (arg1Date == null || arg2Date == null) {
      return null;
    }
    return moment(arg1Date).diff(arg2Date, 'days');
  };

  parser.functions.sum = (arg1, arg2) => arg1 + arg2;
  parser.functions.convertToString = (arg1) => String(arg1);
  parser.functions.toLower = (arg1) => {
    // if string or character return lowercase
    if (typeof arg1 === 'string') {
      return arg1.toLowerCase();
    }
    throw new Error('ToLower function requires string only');
  };
  parser.functions.toUpper = (arg1) => {
    // if string or character return lowercase
    if (typeof arg1 === 'string') {
      return arg1.toUpperCase();
    }
    throw new Error('ToLower function requires string only');
  };

  parser.functions.emptyJSON = () => ({});

  parser.functions.emptyArray = () => [];

  parser.functions.jsonAssign = (arg1, key, value) => ({
    ...arg1,
    [key]: value,
  });

  parser.functions.jsonStringify = (arg1) => {
    try {
      return JSON.stringify(arg1);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Error stringifying JSON: ${JSON.stringify(arg1)}`, err);
      return {
        error: 'JSON failed to stringify',
      };
    }
  };

  parser.functions.toNumber = (arg1) => Number(arg1);
  parser.functions.at = (arg1, value) => {
    // if type is array, return value at index
    if (
      Array.isArray(arg1) ||
      typeof arg1 === 'string' ||
      typeof arg1 === 'object'
    ) {
      if (typeof arg1 === 'object') {
        return value in arg1 ? arg1[value] : null;
      }
      return arg1[value];
    }
    throw new Error('At function requires array or string only');
  };
  parser.functions.split = (arg1, arg2) => {
    const delimiter = arg2 != null ? arg2 : '';
    if (typeof arg1 === 'string') {
      return arg1.split(delimiter);
    }
    throw new Error('Split function requires string only');
  };
  const parsedExpr = parser.parse(formatVariableContentStr(expression));
  const value = parsedExpr.evaluate({ ...variableValues });
  return value;
}

export const calculateExpressionValue = (
  expression: string,
  variableValues: { [variableName: string]: SuperscoreVariableTypesT }
): number | boolean => {
  // if evaluation throws an error, then expression is invalid / has variables
  // not in scope
  const initialVariables = _.keys(variableValues);
  const value = evaluateExpression<number | boolean>({
    expression,
    variableValues,
  });
  // eslint-disable-next-line arrow-parens
  if (
    _.keys(variableValues).some((curVar) => !initialVariables.includes(curVar))
  ) {
    throw new Error(
      `Invalid variables used or assigned in expression, ${expression}`
    );
  }
  return value;
};

const validateBracesInExpressionVariables = (
  expressionVariable,
  expression
) => {
  const enclosedValue = `{${expressionVariable}}`;
  if (expression.includes(enclosedValue) === false) {
    throw new Error(
      `${expressionVariable} was not enclosed in { } in the expression ${expression}`
    );
  }
};

export const calculateConditionalValue = (
  conditional: string,
  variableValues: { [variableName: string]: SuperscoreVariableTypesT },
  options?: { simplifiedExpression: boolean }
): boolean => {
  const initialVariables = _.keys(variableValues);
  const simplifiedExpression =
    options && options.simplifiedExpression !== undefined
      ? options.simplifiedExpression
      : false;
  const formattedExpression = formatVariableContentStr(conditional);

  if (simplifiedExpression) {
    const parserSettings = simplifiedExpression
      ? EXPRESSION_PARSER_SETTINGS
      : PARSER_SETTINGS;
    const parser = new Parser(parserSettings);
    const parsedExpr = parser.parse(formattedExpression);
    parsedExpr.variables().forEach((element) => {
      validateBracesInExpressionVariables(element, conditional);
    });
  }
  const conditionalVal: boolean = evaluateExpression<boolean>({
    expression: formattedExpression,
    variableValues,
    simplifiedExpression,
  });
  if (typeof conditionalVal !== typeof true) {
    throw new Error(
      `Conditional expression must evaluate to a true or false value, ${conditional}`
    );
  }
  // eslint-disable-next-line arrow-parens
  if (
    _.keys(variableValues).some((curVar) => !initialVariables.includes(curVar))
  ) {
    throw new Error(
      `Conditional expression contains unknown variables, ${conditional}`
    );
  }
  return conditionalVal;
};
